import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./RentEquipmentForm.css";

function RentEquipmentForm() {
  return (
    <div className="RentEquipmentFormBG bg-red">
        <h3>Rent Your Equipment Now <span>Tell us about the equipment you are looking to rent through our equipment share program</span></h3>
        <a href="mailto:purchasing@prorental.com" className="btn btn-white">Contact Now</a>
    </div>
  );
}
export default RentEquipmentForm;
