import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Homebanner.css";

function Homebanner() {
  return (
    <>
      <Row className="customRow">
        <Col lg={12}>
          <div className="HomeBanner01">
            {/* <h1>ANYTHING, ANYTIME, ANYWHERE</h1> */}
          </div>
        </Col>
        <Col lg={12}>
          <div className="BannerWrap">
            <Link className="BannerWrap01" to="/">
              <Image src="/img/BannerImg-01.webp" alt="Banner_1" />{" "}
              <h5>
                PRO-GRADE RENTAL EQUIPMENT <small>TM</small>
              </h5>{" "}
              <p>
                PRO-GRADE RENTAL EQUIPMENT equipment is new or like new that
                some contractor's or consumers either need or want to have on
                their project. This is feature packed equipment, highlighting
                the best technological advancements available.
              </p>{" "}
            </Link>
            <Link className="BannerWrap02" to="/">
              <Image src="/img/BannerImg-02.webp" alt="Banner_2" />{" "}
              <h5>
                SEMI-PRO RENTAL EQUIPMENT <small>TM</small>
              </h5>{" "}
              <p>
                SEMI-PRO RENTAL EQUIPMENT equipment with a little more
                experience and age at a LOWER PRICE POINT than our PRO-GRADE
                equipment. This is helpful for some budget sensitive projects or
                projects that just don't require like new equipment like some
                ranch work or DIY PROJECTS. SEMI-PRO grade equipment is also
                perfect option for some serious duty applications when there is
                risk could get scratched paint and decals, overspray from
                painting or concrete on the equipment. Ask our sales person for
                all the options
              </p>
            </Link>
          </div>
        </Col>
        <Col lg={6}>
          <div className="HomeBanner02">
            <h2>
              We offer equipment rentals, sales & service for all your needs.
            </h2>
          </div>
          {/* <div className="HomeBanner03">
            <h6>Service my Equipment</h6>
          </div> */}
        </Col>
        <Col lg={6}>
          <div className="HomeBanner04 HomeBannerDefault">
            <h3>SELL NOW</h3>
            <h6>Sell us your equipment</h6>
          </div>
          <div className="HomeBanner05 HomeBannerDefault">
            <h3>RENT YOUR EQUIPMENT</h3>
            <h6>Equipment Share</h6>
          </div>
          <div className="HomeBanner06 HomeBannerDefault">
            <h3>BUY OVER TIME</h3>
            <h6>Rent to Own</h6>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Homebanner;
