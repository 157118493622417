import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./TeamMembers.css";
const data = [
  {
    title: "Mike Joslin",
    Des: "General Manager Sales & Service",
    SubDes: "Previously with Tellus",
    Email: "sales01@prorental.com",
    Cell: "830-995-5600",
  },
  {
    title: "Robin & Lilia King Owners",
    Des: "Owners",
    Email: "rking@prorental.com",
    Cell: "210-367-0140",
  },
  {
    title: "Avery King",
    Des: "Buying Equipment & Logistics ",
    Email: "aking@prorental.com ",
  },
  {
    title: "Roger O. King IV (Roky) ",
    Des: "VP Operations",
    Email: "roger@prorental.com ",
    Cell: "830-500-3156",
  },
];
function TeamMembers(props) {
  return (
    <>
      <div className="title-wrap">
        <h3 className="main-title">
          Below is direct contact information to some of our staff{" "}
        </h3>
      </div>
      <Row>
        {data.map((product, index) => (
          <Col lg={3} md={6} sm={6}>
            <div className="TeamMembers" key={index}>
              {/* <div className="TeamMembersImg">
              <Image src={product.image} />
            </div> */}
              <div className="TeamMembersInfo">
                <h6>{product.title}</h6>
                <p>
                  {product.Des} <span>{product.SubDes}</span>{" "}
                </p>
                <a href="mailto:{product.Email}">{product.Email}</a>
                <a className="mt-1" href="mailto:{product.cell}">
                  {product.Cell}
                </a>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}
export default TeamMembers;
