import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NeedAssistance.css";

function NeedAssistance() {
  return (
    <div className="WhiteBoxWrap NeedAssistance">
      <div className="boxInfo">
        <h4>Got a problem?</h4>
        <p>
          Have a problem and just want to deal with a Human? Send our manager a
          direct email! 
        </p>
        <a href="mailto:sales@prorental.com" className="btn btn-blue">
          Send us a message
        </a>
      </div>
      <div className="boxImg">
        <Image src="/img/NeedAssistance.webp" alt="Product" />
      </div>
    </div>
  );
}
export default NeedAssistance;
