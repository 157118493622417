// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/RentEquipmentForm-01.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.bg-red.RentEquipmentFormBG{\n    background:#760001;\n}\n\n.RentEquipmentFormBG.bg-red::after {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n}\n", "",{"version":3,"sources":["webpack://./src/Component/RentEquipmentForm/RentEquipmentForm.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,yDAAqE;IACrE,sBAAsB;AAC1B","sourcesContent":["\n.bg-red.RentEquipmentFormBG{\n    background:#760001;\n}\n\n.RentEquipmentFormBG.bg-red::after {\n    background-image: url('../../../public/img/RentEquipmentForm-01.png');\n    background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
