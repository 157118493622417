import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./UsedEquipment.css";

function UsedEquipment() {
  return (
    <div className="">
      <div className="WhiteBoxWrap EquipmentServiced">
        <div className="boxInfo">
          <h4>Buy Top-Tier Used Equipment</h4>
          <p>
            Your partner for a safe and productive worksite. Explore our large
            selection of expertly maintained used equipment for rent.
          </p>
          <a href="mailto:sales@prorental.com" className="btn btn-blue">
            Contact Now
          </a>
        </div>
        <div className="boxImg">
          <Image src="/img/UsedEquipment.webp" alt="Product" />
        </div>
      </div>
    </div>
  );
}
export default UsedEquipment;
