import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HProductListCard from "../../Component/HProductListCard/HProductListCard";
import ProductCategory from "../../Component/ProductCategory/ProductCategory";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import ServicesSolutions from "../../Component/ServicesSolutions/ServicesSolutions";
import DownloadEquipment from "../../Component/DownloadEquipment/DownloadEquipment";
import "./RentPage.css";

function RentPage() {
  return (
    <div className="d-margin">
      <Container>
        <div className="RentInfobanner mb-4">
          <h4>OPTIONS FOR EVERY BUDGET AND APPLICATION: </h4>
          <h6>PRO-GRADE RENTAL EQUIPMENT:  </h6>
          <p>Is NEW or like NEW equipment that some contractor's or consumers either need or want to have on their project</p>
          <h6>SEMI-PRO RENTAL EQUIPMENT: </h6>
          <p>Is equipment with a little more experience and age at a LOWER PRICE POINT than our PRO-GRADE equipment. This is helpful for some budget sensitive projects or projects that just don't require like new equipment like some ranch work or DIY PROJECTS. SEMI-PRO grade equipment is also perfect option for some serious duty applications when there is risk could get scratched paint and decals, overspray from painting or concrete on the equipment. Ask our sales person for all the options</p>
        </div>
          <Row>
            <Col lg={3}>
              <ProductCategory />
            </Col>
            <Col lg={9}>
              <div className="HProductList">
                <h2>Quality Equipment</h2>
                <HProductListCard />
              </div>
            </Col>
          </Row>
          <div className="my-80">
            <ServicesSolutions />
          </div>
          {/* <DownloadEquipment /> */}
          <Row>
            <Col lg={6}>
                <NeedAssistance />
            </Col>
            <Col lg={6}>
                <CallAvailable />
            </Col>
            </Row>
      </Container>
    </div>
  );
}
export default RentPage;
