import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./RentMyequipment.css";

function RentMyequipment() {
  return (
    <div className="my-80">
        <Row className="align-items-center">
            <Col lg={5}>
                <div className="rentEquipmentInfo">
                    <h3>Idle equipment back at the office? </h3>
                    <p>We all have it, Equipment that sits idle back at the office or job site because there is no need for it on any of your current jobs or during the off season.</p>
                    <p>For most, this is just the cost of doing business. But have you considered alternative ways to make money with that equipment?</p>
                    <p>A way to increase your bottom line without increasing your operating expenses or investing into further infrastructure and new business processes?</p>
                    <p>With Pro Rental Equipment Share you can do just that. Create additional income for all your idle equipment, let us do the dirty work for you. We will handle the entire process, and send you a check!</p>
                    <p>Contact us today at 830-995-5600 for more information and get setup with our program!</p>
                </div>
            </Col>
            <Col lg={7} className="C-order">
                <div className="rentEquipmentImg rentEquipmentImg-02">
                    <Image src="/img/RentEquipment-03.jpg" />
                </div>
            </Col>
        </Row>
    </div>
  );
}
export default RentMyequipment;
