import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./WhyProRental.css";

function WhyProRental() {
  return (
    <Row className="align-items-center my-80">
      <Col lg={6}>
        <div className="Defalut-img right-blue">
          <Image src="/img/WhyProRental.webp" alt="Image" />
        </div>
      </Col>
      <Col lg={6}>
        <div className="Defalut-info ms-auto">
          <h3>Why should I choose Pro Rentals?</h3>
          <p>
            You can count on our team of industry experts, top-tier equipment
            and tools, and Instant customer service to keep your operations
            running smoothly, day or night.
          </p>
          <p>
            You can rent top-quality and expertly maintained equipment at
            ProRentals. With a dedicated team of customer service and industry
            experts, we’re here to help keep projects on time and on budget.
          </p>
          <p>
            Our inventory of small and large equipment rentals includes
            solutions for difficult climates, visibility blockers, tough
            terrain, high spaces and unique situations. You can trust us to have
            the tools and equipment you need when you need them.
          </p>
        </div>
      </Col>
    </Row>
  );
}
export default WhyProRental;
