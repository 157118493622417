import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import RentEquipmentProcess from "../../Component/RentEquipmentProcess/RentEquipmentProcess";
import ServiceBox from "../../Component/ServiceBox/ServiceBox";
import "./ServicePage.css";

function ServicePage() {
  return (
    <div className="d-margin">
      <Container>
          <div className="HProductList">
              <h2>Equipment Services & Our Services</h2>
          </div>
          <div className="my-80">
            <ServiceBox />
          </div>
          <Row>
            <Col lg={6}>
                <NeedAssistance />
            </Col>
            <Col lg={6}>
                <CallAvailable />
            </Col>
          </Row>
          {/* <RentEquipmentProcess /> */}
      </Container>
    </div>
  );
}
export default ServicePage;
