import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./SellMyequipment.css";

function SellMyequipment() {
  return (
    <div className="my-80">
        <Row className="align-items-center">
            <Col lg={5} md={6}>
                <div className="rentEquipmentInfo">
                    <h3>Why should I choose Pro Rentals to sell my equipment?</h3>
                    <p>You can count on our team of industry experts, to provide maximum value on your assets that you no longer need or want. You don't need to wait for days, weeks, or even months for your equipment to sell on a marketplace or to get an appraisal. Pro Rental can do this for you immediately!</p>
                    <p>The process is simple, take a picture of your units manufacturers tag, then take several pictures of the unit. Email them to us at <a href="mailto:purchasing@prorental.com">purchasing@prorental.com</a> and we would be happy to provide a cash offer within 48 hours!</p>
                    <p>Thank you for considering Pro Rental!</p>
                </div>
            </Col>
            <Col lg={7} md={6} className="C-order">
                <div className="rentEquipmentImg rentEquipmentImg-01">
                    <Image src="/img/RentEquipment-02.png" />
                </div>
            </Col>
        </Row>
    </div>
  );
}
export default SellMyequipment;
