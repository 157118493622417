import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CallAvailable.css";

function CallAvailable() {
  return (
    <div className="WhiteBoxWrap NeedAssistance CallAvailable">
      <div className="boxInfo">
        <h4>We are Available</h4>
        {/* <h5>ANYTHING, ANYTIME, ANYWHERE</h5> */}
        <p>
          Click below to send a message or call us at the number on the top and
          bottom of the page to quickly connect with an expert.
        </p>
        <a href="tel:830-995-5600" className="btn btn-blue">
          Call us at (830-995-5600)
        </a>
      </div>
      <div className="boxImg">
        <Image src="/img/CallAvailable.webp" alt="Product" />
      </div>
    </div>
  );
}
export default CallAvailable;
