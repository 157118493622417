import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SellEquipmentForm.css";

function SellEquipmentForm() {
  return (
    <div className="RentEquipmentFormBG">
        <h3>Let's Get Started. <span>Tell us about the equipment you are looking to sell</span></h3>
        <a href="mailto:purchasing@prorental.com" className="btn btn-white">Contact Now</a>
    </div>
  );
}
export default SellEquipmentForm;
