import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HowWeWork from "../../Component/HowWeWork/HowWeWork";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import OurPurpose from "../../Component/OurPurpose/OurPurpose";
import TeamMembers from "../../Component/TeamMembers/TeamMembers";
import "./AboutPage.css";

function AboutPage() {
  return (
    <div className="d-margin">
      <Container>
        <div className="HProductList">
          <h2>Locally Owned & Managed</h2>
        </div>
        <div className="my-80">
          <HowWeWork />
        </div>
          <TeamMembers />
        <OurPurpose />
        <Row>
            <Col lg={6}>
                <NeedAssistance />
            </Col>
            <Col lg={6}>
                <CallAvailable />
            </Col>
          </Row>
      </Container>
    </div>
  );
}
export default AboutPage;
