export const baseUrl =
  "https://procontainerapi20230623165656.azurewebsites.net/api/";
// export const baseUrl = "https://localhost:7064/api/";
export const AppbaseUrl = "https://procontainers.com/";

export const LogicAppEmailUrlOffer =
  "https://prod-53.eastus.logic.azure.com:443/workflows/084b013dc6f94e688c6be8dfcefb277e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=u83DLRiy3OVRUH5XLuJcLYnjOp7fSULFUkmjxayxh3A";

// test mode stripe
export const stripePublishedKey =
  "pk_test_51NLpFEDPeMrjygaTksvguppLMP5vjG6a5uekJ3bz9MpvNVhoqthLqe6YLci1XMiGdyDWzizYCeK1dB0pW2eLKE8M00QTO9rq6Y";
export const LogicAppEmailUrl =
  "https://prod-53.eastus.logic.azure.com:443/workflows/084b013dc6f94e688c6be8dfcefb277e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=u83DLRiy3OVRUH5XLuJcLYnjOp7fSULFUkmjxayxh3A";

// export const emailToAddress = "salse@procontainer.com";
export const emailToAddress = "dev04@kingsurplus.com";

export const config = {
  headers: {
    "QB-Realm-Hostname": "https://kingsurplus-9736.quickbase.com",
    "User-Agent": "{User-Agent}",
    Authorization: "QB-USER-TOKEN b6npp9_pxtb_0_dukcgfwb6cgeyjdw39gikyf6w46",
    "Content-Type": "application/json",
  },

  qbOrderTbl: "bth6srh2x",
};

export const carriersId = {
  ODFL: "b4f53ac9-8fb6-4ed3-87bd-343ee192f879",
  SAIA: "4430a2a5-82dc-4a48-99f3-10f31c1c6057",
  SEFL: "abc2f3ae-05f4-4113-b576-2326ce458502",
  XPO: "76057095-250d-40d2-9d18-e8682be8f735",
  UPS: "se-4236612",
  FedEx: "se-4236084",
  Stamps: "se-4236116",
};

export const carriersName = {
  ODFL: "Old Dominion Freight Line",
  SAIA: "SAIA Motor Freight Lines Inc",
  SEFL: "Southeastern Freight Lines",
  SEFL: "Southeastern Freight Lines",
  UPS: "United Parcel Service",
  FedEx: "Federal Express",
  Stamps: "Stamps.com",
};

export const ODFlServiceNames = {
  stnd: "Standard",
  gtd_noon: "Guaranteed Noon",
  gtd_pm: "Guaranteed End of Day",
};

export const ODFLserviceOptions = {
  aptd: "Appointment required at delivery",
  cnstd: "Construction site delivery",
  idl: "Inside delivery",
  lftd: "Lift gate required at delivery",
  ltdad: "Limited access delivery",
  res: "Residential delivery",
};

export const EstimatedConditionWeight = {
  maxPostalShipping: 150,
  maxFreightShipping: 4500,
  maxUspsShipping: 70,
};

export function getFormDataHtml(formData) {
  const formDataEntries = Object.entries(formData);
  var template = `<div style="font-family: Segoe UI; max-width: 70%; margin: 0 auto; padding: 20px;">`;
  template =
    template +
    `<img src="https://prorental.blob.core.windows.net/pro-container-blob/newtmlogo.webp" alt="Logo" style="display: block; margin: 0 auto; width: 50%;">`;
  template = template + `<ul style="list-style: none; padding: 0;">`;
  for (let [key, value] of formDataEntries.entries()) {
    template =
      template +
      `<li><strong style="text-transform: capitalize" > ${convertCamelCaseToWords(
        value[0]
      )} = </strong> ${value[1]}</li>`;
  }
  template = template + `</ul></div>`;
  return template;
}

const convertCamelCaseToWords = (camelCaseWord) => {
  const words = camelCaseWord.split(/(?=[A-Z])/); // Split the camelCase word based on capital letters
  const convertedWords = words.map((word) => word.toLowerCase());
  return convertedWords.join(" ");
};
