// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/HomeBanner01.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubPageBanner{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    padding: 70px 40px;\n}\n.SubPageBanner h3{\n    font-size: 38px;\n    color: #fff;\n    position:relative;\n    z-index:5;\n    font-weight: 700;\n}\n\n@media (max-width:1429px){\n    .SubPageBanner h3{\n        font-size:32px\n    }\n}\n@media (max-width:1199px){\n    .SubPageBanner h3{\n        font-size:28px\n    }\n}\n@media (max-width:991px){\n    .SubPageBanner h3{\n        font-size:26px\n    }\n}\n@media (max-width:767px){\n    .SubPageBanner h3{\n        font-size:24px\n    }\n    .SubPageBanner{\n        padding: 60px 20px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/Pages/RentUsEquipment/RentUsEquipment.css"],"names":[],"mappings":"AAAA;IACI,yDAA6D;IAC7D,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,WAAW;IACX,iBAAiB;IACjB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI;QACI;IACJ;AACJ;AACA;IACI;QACI;IACJ;AACJ;AACA;IACI;QACI;IACJ;AACJ;AACA;IACI;QACI;IACJ;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".SubPageBanner{\n    background-image: url('../../../public/img/HomeBanner01.png');\n    padding: 70px 40px;\n}\n.SubPageBanner h3{\n    font-size: 38px;\n    color: #fff;\n    position:relative;\n    z-index:5;\n    font-weight: 700;\n}\n\n@media (max-width:1429px){\n    .SubPageBanner h3{\n        font-size:32px\n    }\n}\n@media (max-width:1199px){\n    .SubPageBanner h3{\n        font-size:28px\n    }\n}\n@media (max-width:991px){\n    .SubPageBanner h3{\n        font-size:26px\n    }\n}\n@media (max-width:767px){\n    .SubPageBanner h3{\n        font-size:24px\n    }\n    .SubPageBanner{\n        padding: 60px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
