// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/img/contactUs.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactBanner {\n    background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: right;\n    background-size: cover;\n    background-repeat: no-repeat;\n    position: relative;\n    background-position: 100% 22%;\n}\n.ContactBanner::after {\n    content: \"\";\n    background: #0000006b;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n}", "",{"version":3,"sources":["webpack://./src/Pages/ContactPage/ContactPage.css"],"names":[],"mappings":"AAAA;IACI,wDAAyD;IACzD,0BAA0B;IAC1B,sBAAsB;IACtB,4BAA4B;IAC5B,kBAAkB;IAClB,6BAA6B;AACjC;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,UAAU;AACd","sourcesContent":[".ContactBanner {\n    background-image:url('../../../public/img/contactUs.jpg');\n    background-position: right;\n    background-size: cover;\n    background-repeat: no-repeat;\n    position: relative;\n    background-position: 100% 22%;\n}\n.ContactBanner::after {\n    content: \"\";\n    background: #0000006b;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
