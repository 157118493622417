import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./OurPurpose.css";

function OurPurpose() {
  return (
    <div className="">
        <div className="WhiteBoxWrap EquipmentServiced">
            <div className="boxInfo">
                <h4>Our purpose: To build a better future together</h4>
                <p>We work together with our customers, communities and employees to find solutions with a shared commitment to service and safety. To us, this is a simple, but powerful notion. It’s a shared mindset. One of partnership that helps us face any challenge, together.</p>
            </div>
            <div className="boxImg">
                <Image src="/img/OurPurpose.png" />
            </div>
        </div>
    </div>
  );
}
export default OurPurpose;
