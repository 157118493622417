import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./EquipmentServiced.css";

function EquipmentServiced() {
  return (
    <></>
    // <div className="my-80 mb-0">
    //     <div className="WhiteBoxWrap EquipmentServiced">
    //         <div className="boxInfo">
    //             <h4>Need your Equipment Serviced?</h4>
    //             <p>Your partner for a safe and productive worksite. Explore our large selection of expertly maintained used equipment for rent.</p>
    //             <a href="mailto:sales@prorental.com" className="btn btn-blue">Contact Now</a>
    //         </div>
    //         <div className="boxImg">
    //             <Image src="/img/EquipmentServiced.png" />
    //         </div>
    //     </div>
    // </div>
  );
}
export default EquipmentServiced;
