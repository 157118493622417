import { Icon } from "@iconify/react";
import React, { useState } from "react";
import {
  CloseButton,
  Container,
  Image,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";

function Header() {
  const [headerClassName, setHeaderClassName] = useState("");

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "fixed" && window.pageYOffset >= 5) {
      setHeaderClassName("fixed");
    } else if (headerClassName === "fixed" && window.pageYOffset < 5) {
      setHeaderClassName("");
    }
  };

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); // IMPORTANT, This will cause react to update depending on change of this value

  return (
    <>
      <div className="topbar">
        <Container>
          <ul>
            <li>
              <a href="mailto:sales@prorental.com">
                <span>
                  <Icon icon="ic:round-mail" />
                </span>{" "}
                sales@prorental.com
              </a>
            </li>
            <li>
              <a href="tel:830-995-5600">
                <span>
                  <Icon icon="ic:baseline-call" />
                </span>{" "}
                830-995-5600
              </a>
            </li>
          </ul>
        </Container>
      </div>
      <header className={headerClassName}>
        {["lg"].map((expand) => (
          <Navbar key={expand} expand={expand}>
            <Container>
              <Navbar.Brand href="/">
                <Image src="/img/logo.svg" alt="ProRental" />
              </Navbar.Brand>
              <Navbar.Toggle
                className="ms-auto"
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Link onClick={CloseButton} to="/">
                      Home
                    </Link>
                    <a
                      onClick={CloseButton}
                      href="https://store.prorental.com/items"
                    >
                      Rent Now
                    </a>
                    <Link onClick={CloseButton} to="/EquipmentSales">
                      Equipment Sales
                    </Link>
                    {/* <Link onClick={CloseButton} to="/Service">Service</Link> */}
                    <Link onClick={CloseButton} to="/SellSales">
                      Sell Your Equipment
                    </Link>
                    <Link onClick={CloseButton} to="/RentUs">
                      EquipShare
                    </Link>
                    <Link onClick={CloseButton} to="/About">
                      Our Company
                    </Link>
                    <Link onClick={CloseButton} to="/Contact">
                      Contact Us
                    </Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </header>
    </>
  );
}
export default Header;
