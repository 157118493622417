import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HowWeWork.css";

function HowWeWork() {
  return (
    <div className="">
        
        <div className="title-wrap">
            <h3 className="main-title">How We Work </h3>
            <p>Teamwork, safety and customer service are at the heart of everything we do. Get to know who we are and what we stand for</p>
        </div>
        <Row>
            <Col lg={3} md={6}>
                <div className="HowWorkItem">
                    <h5>The strongest team in the industry</h5>
                    <p>We are a diverse, inclusive, innovative team of experts with can-do attitudes. Safety is at our core and so is taking care of our customers. And each other.</p>
                </div>
            </Col>
            <Col lg={3} md={6}>
                <div className="HowWorkItem">
                    <h5>Purpose-driven and committed to excellence</h5>
                    <p>we are committed to a standard of excellence in every aspect of our business and to ethical, responsible governance and conduct in all phases of our operations. we continue to build long-term value for all stakeholders.</p>
                </div>
            </Col>
            <Col lg={3} md={6}>
                <div className="HowWorkItem">
                    <h5>Performance and Efficiency for the worksite</h5>
                    <p>Our equipment enable you to get the job done! Stop worrying about equipment breaking down and causing even more delays! Our equipment will keep you going!</p>
                </div>
            </Col>
            <Col lg={3} md={6}>
                <div className="HowWorkItem">
                    <h5>Working with others to give back</h5>
                    <p>ProRentals is proud to partner with organizations that have the same commitment to bettering our communities. Learn more about our ongoing support and other partnerships with organizations who help us build an even better future.</p>
                </div>
            </Col>
        </Row>
    </div>
  );
}
export default HowWeWork;
