import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./BrandEquipment.css";

function BrandEquipment() {
  return (
    <>
      <h3 className="main-title">Top Brand Equipment </h3>
      <ul className="BrandLogos">
        <li>
          <Image src="/img/equipment-01.webp" alt="Product" />
        </li>
        <li>
          <Image src="/img/equipment-02.webp" alt="Product" />
        </li>
        <li>
          <Image src="/img/equipment-03.webp" alt="Product" />
        </li>
        <li>
          <Image src="/img/equipment-04.webp" alt="Product" />
        </li>
        <li>
          <Image src="/img/equipment-05.webp" alt="Product" />
        </li>
      </ul>
    </>
  );
}
export default BrandEquipment;
