import { Icon } from "@iconify/react";
import React,{useState} from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from 'axios';
import "./ContactForm.css";
import EmailBody from '../EmailBody/EmailBody';
import ReactDOMServer from 'react-dom/server';

function ContactForm() {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(false);
    const [response, setResponse] = useState(null);
    const [formData, setFormData] = useState({
        firtName: '',
        lastName: '',
        email: '',
        companyName:'',
        phoneNumber:'',
        subject:'',
        city:'',
        postal:'',
        isReplyEmail:'',
        isReplyPhone:'',
        isReplyNotRequired:'',
        message:'',
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        // setFormData({
        //     ...formData,
        //     [event.target.name]: event.target.value
        // });

        type === "checkbox"
        ? setFormData({ ...formData, [name]: checked })
        : setFormData({ ...formData, [name]: value });
    }


    const sendEmail = async () => {

        const emailBody = ReactDOMServer.renderToString(<EmailBody firtName={formData.firtName} lastName={formData.lastName} email={formData.email} companyName={formData.companyName}  phoneNumber={formData.phoneNumber} subject={formData.subject} city={formData.city} postal={formData.postal} isReplyEmail={formData.isReplyEmail} isReplyPhone={formData.isReplyPhone} isReplyNotRequired={formData.isReplyNotRequired} message={formData.message}  />);

        const data = {
           "to": "sales@prorental.com",
            "subject": "New Customer Contact Form Information",
            "body":emailBody,
          }
          axios.post('https://prod-03.centralus.logic.azure.com:443/workflows/b960253aa74a4d908ed35951cb2c9df9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=08xRwxo5OmTjgnYzXDjCubcJ6qhZ7mgWPXZiQWqucHo',
           data )
            .then(response => {
              console.log("email send result ============>", response);

              setFormSubmitted(true);
              setFormError(false);
              setFormData({
                firtName: '',
                lastName: '',
                email: '',
                companyName: '',
                phoneNumber: '',
                subject: '',
                city: '',
                postal: '',
                isReplyEmail: '',
                isReplyPhone: '',
                isReplyNotRequired: '',
                message: '',
            });
             
            })
            .catch(error => {
                setFormError(true);
              console.log(error);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("from data")
        console.log(formData);
        sendEmail();
    }
    

  return (
    <>
      <Row>
        <Col lg={9}>
            <Form  onSubmit={handleSubmit} className="contactForm">
                <h3>Send us a Message</h3>
                <p>A customer care representative will respond to you shortly.</p>
                <Row>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>First Name</label>
                            <input    type="text" name="firtName" value={formData.firtName} onChange={handleChange} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>Last Name</label>
                            <input type="text" name="lastName"   value={formData.lastName} onChange={handleChange} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>Email</label>
                            <input type="text" name="email"  value={formData.email} onChange={handleChange}  />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>Company Name</label>
                            <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>Phone Number</label>
                            <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}  />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>Subject</label>
                            <input type="text" name="subject" value={formData.subject} onChange={handleChange} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>City</label>
                            <input type="text" name="city"   value={formData.city} onChange={handleChange} />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="FromControl">
                            <label>Postal/Zip Code</label>
                            <input type="text" name="postal" value={formData.postal} onChange={handleChange} />
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="FromControl d-flex">
                            <label><strong>How Can We Reply :</strong> </label>
                            <label>
                                <input type="checkbox" name="isReplyEmail" value={formData.isReplyEmail} onChange={handleChange}  />
                                <p>Email</p>
                            </label>
                            <label>
                                <input type="checkbox" name="isReplyPhone" value={formData.isReplyPhone} onChange={handleChange}  />
                                <p>Phone</p>
                            </label>
                            <label>
                                <input type="checkbox" name="isReplyNotRequired" value={formData.isReplyNotRequired} onChange={handleChange} />
                                <p>No Reply Required</p>
                            </label>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="FromControl">
                            <label>Message</label>
                            <textarea rows="5" name="message" value={formData.message} onChange={handleChange} ></textarea>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <Button  type="submit" variant="" className="btn btn-blue">Submit</Button>
                    </Col>
                </Row>
            </Form>
            {formSubmitted && <p className="success-message">
                Your form has been submitted successfully!</p>}
            {formError && <p className="error-message">There was an error submitting your form. Please try again.</p>}
        </Col>
        <Col lg={3}>
            <div className="contactBox">
                <ul>
                    <li><Link to="/"><Icon icon="ic:baseline-mail" /> sales@prorental.com</Link></li>
                    <li><Link to="/"><Icon icon="material-symbols:call" /> +1 830-995-5600</Link></li>
                    <li><Link to="/"><Icon icon="material-symbols:location-on" /> 104 US Highway 87 Comfort, TX - 78013</Link></li>
                </ul>
                <a href="tel:830-995-5600" className="btn btn-blue">CALL US NOW</a>
            </div>
        </Col>
      </Row>
    </>
  );
}
export default ContactForm;
