import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./ServicesSolutions.css";

function ServicesSolutions() {
  return (
    <div>
        <h3 className="main-title">Our Services and Solutions </h3>
        <Row className="justify-content-center">
            <Col lg={4}>
                <div className="serviceItem">
                    <div className="serviceItemImg">
                        <Image src="/img/serviceItemImg-01.png" />
                    </div>
                    <h6>Tired of the Equipment you Rent Breaking Down? <strong>ProRental maintains</strong> our fleet to the highest standard.</h6>
                </div>
            </Col>
            <Col lg={4}>
                <div className="serviceItem">
                    <div className="serviceItemImg">
                        <Image src="/img/serviceItemImg-02.png" />
                    </div>
                    <h6><strong>Instant Response.</strong> Always on Call, Always Available. That's our Standard!</h6>
                </div>
            </Col>
            <Col lg={4}>
                <div className="serviceItem">
                    <div className="serviceItemImg">
                        <Image src="/img/serviceItemImg-03.png" />
                    </div>
                    <h6><strong>Delivery and Pickup.</strong> Need a machine at 3AM on a critical project? We have you covered! </h6>
                </div>
            </Col>
        </Row>
    </div>
  );
}
export default ServicesSolutions;
