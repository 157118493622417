import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BrandEquipment from "../../Component/BrandEquipment/BrandEquipment";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import ChoosingEquipment from "../../Component/ChoosingEquipment/ChoosingEquipment";
import EquipmentServiced from "../../Component/EquipmentServiced/EquipmentServiced";
import Homebanner from "../../Component/Homebanner/Homebanner";
import HProductListCard from "../../Component/HProductListCard/HProductListCard";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import ProductCategory from "../../Component/ProductCategory/ProductCategory";
import UsedEquipment from "../../Component/UsedEquipment/UsedEquipment";
import WhyProRental from "../../Component/WhyProRental/WhyProRental";
import "./HomePage.css";

function HomePage() {
  return (
    <div className="d-margin">
      <Container>
        <Homebanner />
        <div className="my-80">
          <Row>
            <Col lg={3}>
              <ProductCategory />
            </Col>
            <Col lg={9}>
              <div className="HProductList HProductListNoWrap">
                <h2>Quality Equipment</h2>
                <HProductListCard />
              </div>
            </Col>
          </Row>
        </div>
        <ChoosingEquipment />
        {/* <RentEquipment /> */}
        <WhyProRental />
        <BrandEquipment />
        <EquipmentServiced />
        <Row>
          <Col lg={6}>
            <NeedAssistance />
          </Col>
          <Col lg={6}>
            <CallAvailable />
          </Col>
        </Row>
        <UsedEquipment />
      </Container>
    </div>
  );
}
export default HomePage;
