import { Icon } from "@iconify/react";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo from "../../assets/images/logo.svg";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col lg={4}>
            <Link to="/" className="footer-logo">
              <Image src="./img/logo.svg" alt="ProRental" />
            </Link>
            {/* <p><strong>ANYTHING, ANYTIME, ANYWHERE</strong></p> */}
            <ul className="social-icons">
              <li>
                <a href="https://www.facebook.com/prorentalco">
                  <Icon icon="ic:round-facebook" />
                </a>
              </li>
              {/* <li><a to="/"><Icon icon="ph:instagram-logo-fill" /></a></li>
                        <li><a to="/"><Icon icon="mdi:twitter" /></a></li>
                        <li><a to="/"><Icon icon="ri:youtube-fill" /></a></li>
                        <li><a to="/"><Icon icon="ri:linkedin-box-fill" /></a></li> */}
            </ul>
          </Col>
          <Col lg={5}>
            <div className="links_Wrap">
              <div className="links_items">
                <h6>Quick Links</h6>
                <ul>
                  <li>
                    <Link to="/">Inventory</Link>
                  </li>
                  <li>
                    <Link to="/">About Us</Link>
                  </li>
                  <li>
                    <Link to="/">Our Services</Link>
                  </li>
                  <li>
                    <Link to="/">Sell with us</Link>
                  </li>
                  <li>
                    <Link to="/">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="links_items">
                <h6>Support Links</h6>
                <ul>
                  <li>
                    <Link to="/">Terms of services</Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Carrier</Link>
                  </li>
                  {/* <li><Link to="/">Login us</Link></li> */}
                  {/* <li><Link to="/">Signup</Link></li> */}
                </ul>
              </div>
              <div className="links_items">
                <h6>Contacts</h6>
                <ul>
                  <li>
                    <Link to="/">
                      <Icon icon="ic:baseline-mail" /> sales@prorental.com
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <Icon icon="material-symbols:call" /> +1 830-995-5600
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <Icon icon="material-symbols:location-on" /> 104 US
                      Highway 87 Comfort, TX - 78013
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="newsletterwrap">
              <h6>Have feedback for us? </h6>
              <p>
                Good or Bad (We hope good) we want to hear from you, below you
                can quickly submit feedback anonymously, or you can include your
                information. Our team will pick it up and reach out to you!{" "}
              </p>
              <div className="search_footer">
                <input type="text" placeholder="Enter your Feedback here" />
                <Link to="/">
                  <Icon icon="bi:send-fill" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <small className="copyrights">
        Copyright @2022 Prorental. All Rights Reserved. Designated trademarks
        and brands are the property of their respective owners.{" "}
      </small>
    </footer>
  );
}
export default Footer;
