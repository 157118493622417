import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import "./HProductListCard.css";
const data = [
  {
    image: "/img/Product-Item/product-01.png",
    title: "Backhoe",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-02.png",
    title: "Bull Dozers",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-03.png",
    title: "Large, Medium & Mini Excavators",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-04.png",
    title: "Excavator Hammers",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-05.png",
    title: "Compact Trackloaders",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-06.png",
    title: "Skid Steers",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-07.png",
    title: "Wheel Loaders",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-08.png",
    title: "Motor Graders",
    description: "Earth moving Equipment",
  },
  {
    image: "/img/Product-Item/product-09.png",
    title: "Rollers Vib, Static & Rubber",
    description: "Surface Preparation",
  },
  {
    image: "/img/Product-Item/product-10.png",
    title: "Water Truck",
    description: "Surface Preparation",
  },
  {
    image: "/img/Product-Item/product-11.png",
    title: "Rock Crushing & Road Milling",
    description: "Surface Preparation",
  },
  {
    image: "/img/Product-Item/product-12.png",
    title: "Rock Crushing & Road Milling",
    description: "Surface Preparation",
  },
  {
    image: "/img/Product-Item/product-13.png",
    title: "Forestry Mulchers",
    description: "Land Clearning",
  },
  {
    image: "/img/Product-Item/product-14.png",
    title: "Cedar Mulchers",
    description: "Land Clearning",
  },
  {
    image: "/img/Product-Item/product-15.png",
    title: "Towable Diesel Wood Chippers",
    description: "Land Clearning",
  },
  {
    image: "/img/Product-Item/product-16.png",
    title: "Brush Cutters",
    description: "Land Clearning",
  },
  {
    image: "/img/Product-Item/product-17.png",
    title: "Warehouse Forklifts",
    description: "Warehouse Forklifts",
  },
  {
    image: "/img/Product-Item/product-18.png",
    title: "Reach Forklifts",
    description: "Forklifts & Material Handling",
  },
  {
    image: "/img/Product-Item/product-19.png",
    title: "Scissor Lifts 19'-30'",
    description: "Aerial Work Platforms",
  },
  {
    image: "/img/Product-Item/product-20.png",
    title: "Boom Lift 20'-65'",
    description: "Aerial Work Platforms",
  },
];
function HProductListCard(props) {
  return (
    <>
      <Row>
        {data.map((product, index) => (
          <Col lg={3} key={index}>
            <div className="HproductCard">
              <div className="HproductCardImg">
                <Image src={product.image} alt="Product" />
              </div>
              <div className="HproductCardInfo">
                <h6>{product.title}</h6>
                <p>{product.description}</p>
                {/* <Col lg={6} className="flex"> */}
                <div className="btn-container">
                  <a
                    className="btn btn-blue btn-cust"
                    href="https://store.prorental.com/items"
                  >
                    Rent Now
                  </a>
                  {/* <button className="btn btn-blue btn-cust">
                    View Details
                  </button> */}
                </div>
                {/* </Col> */}
                <Col lg={6}></Col>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <h4>Specialty Equipment</h4>
      <Row>
        <Col lg={4}>
          <div className="HproductCard">
            <div className="HproductCardImg">
              <Image src="/img/Product-Item/product-21.png" alt="HP_Product" />
            </div>
            <div className="HproductCardInfo">
              <h6>Presure Digger 6X6 </h6>
              <p>Drilling & Mining</p>
              <a className="btn btn-blue" href="mailto:sales@prorental.com">
                Contact Now
              </a>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="HproductCard">
            <div className="HproductCardImg">
              <Image src="/img/Product-Item/product-22.png" alt="Product" />
            </div>
            <div className="HproductCardInfo">
              <h6>
                Portable Aggregate Screening Plants To Fit Your Material &
                Finished Spec Requirements
              </h6>
              <a className="btn btn-blue" href="mailto:sales@prorental.com">
                Contact Now
              </a>
            </div>
          </div>
        </Col>
      </Row>
      <h4>
        Short & Long-Term Storage Containers Available To Rent On Your Site Or
        Ours Starting At $99 Per Month!{" "}
      </h4>
      <Row>
        <Col lg={12}>
          <div className="HproductCard">
            <div className="HproductCardImg">
              <Image src="/img/Product-Item/product-23.png" alt="Product" />
            </div>
            <div className="HproductCardInfo">
              <h6>We Also Have New & Used Containers For Sale</h6>
            </div>
          </div>
        </Col>
      </Row>
      {/* <p className="noteTExt">
        <strong>OPTIONS FOR EVERY BUDGET AND APPLICATION:</strong> We carry two grades of equipment: <br />
        <strong>PRO-GRADE RENTAL EQUIPMENT:</strong> Is NEW or like NEW equipment that some contractor's or consumers either need or want to have on their project<br />
        <strong>SEMI-PRO RENTAL EQUIPMENT:</strong> This equipment has a lower price point than our PRO GRADE equipment, which is helpful for some budget sensitive projects or ranch work. It is also a perfect option for some serious duty applications, where there is high risk levels that the machine might get scratched or damaged, painted, or even utilized with wet concrete.
      </p> */}
    </>
  );
}
export default HProductListCard;
