import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import ContactForm from "../../Component/ContactForm/ContactForm";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import "./ContactPage.css";

function ContactPage() {
  return (
    <div className="d-margin">
      <Container>
         <div className="SubPageBanner ContactBanner">
            <h3>Contact Us</h3>
         </div>
         <div className="my-80">
            <ContactForm />
         </div>
         <Row>
            <Col lg={6}>
                <NeedAssistance />
            </Col>
            <Col lg={6}>
                <CallAvailable />
            </Col>
          </Row>
      </Container>
    </div>
  );
}
export default ContactPage;
