import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import RentEquipmentForm from "../../Component/RentEquipmentForm/RentEquipmentForm";
import RentEquipmentProcess from "../../Component/RentEquipmentProcess/RentEquipmentProcess";
import SellEquipmentForm from "../../Component/SellEquipmentForm/SellEquipmentForm";
import SellEquipmentProcess from "../../Component/SellEquipmentProcess/SellEquipmentProcess";
import SellMyequipment from "../../Component/SellMyequipment/SellMyequipment";
import "./SellSalesPage.css";

function SellSalesPage() {
  return (
    <div className="d-margin">
      <Container>
         <div className="SubPageBanner">
            <h3>Sell Us Your Used Equipment </h3>
         </div>
          <SellMyequipment />
         <div className="my-80">
          <SellEquipmentProcess />
         </div>
         <SellEquipmentForm />
         <Row>
            <Col lg={6}>
                <NeedAssistance />
            </Col>
            <Col lg={6}>
                <CallAvailable />
            </Col>
          </Row>
      </Container>
    </div>
  );
}
export default SellSalesPage;
