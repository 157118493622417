import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import Header from "./Component/Header/Header";
import "./font/stylesheet.css";
import Footer from "./Component/Footer/Footer";
import RentPage from "./Pages/RentPage/RentPage";
import EquipmentSalesPage from "./Pages/EquipmentSalesPage/EquipmentSalesPage";
import ServicePage from "./Pages/ServicePage/ServicePage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import RentUsEquipment from "./Pages/RentUsEquipment/RentUsEquipment";
import SellSalesPage from "./Pages/SellSalesPage/SellSalesPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import InventoryDetail from "./Pages/InventoryDetail/InventoryDetail";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rent" element={<RentPage />} />
        <Route path="/EquipmentSales" element={<EquipmentSalesPage />} />
        <Route path="/Service" element={<ServicePage />} />
        <Route path="/About" element={<AboutPage />} />
        <Route path="/RentUs" element={<RentUsEquipment />} />
        <Route path="/SellSales" element={<SellSalesPage />} />
        <Route path="/Contact" element={<ContactPage />} />
        <Route path="/InventoryDetail/:id" element={<InventoryDetail />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
