import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ProductCategory.css";

function ProductCategory() {
  return (
    <div className="CategoryList">
      <h6>Categories Available</h6>
      <ul>
        <li>
          <Link to="/">Aerial Work Platforms</Link>
        </li>
        <li><Link to="/">Compaction</Link></li>
        <li><Link to="/">Concrete & Masonry</Link></li>
        <li><Link to="/">Earthmoving Equipment</Link></li>
        <li><Link to="/">Forklifts</Link></li>
        <li><Link to="/">Material Handling</Link></li>
        <li><Link to="/">Other Equipment</Link></li>
        <li><Link to="/">Storage Containers & Mobile...</Link></li>
        <li><Link to="/">Surface Preparation</Link></li>
        <li><Link to="/">Trucks & Trailers</Link></li>
        <li><Link to="/">Drilling & Mining</Link></li>
        <li><Link to="/">Aggregate</Link></li>
        <li><Link to="/">Storage</Link></li>
        <li><Link to="/">Construction</Link></li>
      </ul>
    </div>
  );
}
export default ProductCategory;
