import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./ServiceBox.css";

function ServiceBox() {
  return (
    <>
     <Row className="align-items-center">
      <Col lg={6}>
        <div className="Defalut-info mx-auto">
          <h3>Heavy Equipment Servicing & Repair In Comfort, TX </h3>
          <p>Heavy Equipment Servicing & Repair In Comfort, TX 
            When you're invested in making sure your equipment stays in great working order for years to come, you need knowledge and skill you can count on. Our highly trained technicians are dedicated to maintaining and servicing your loaders, excavators, dozers, skid steers, and other equipment so you can get the most out of your investments. Here at Pro Rental, we know that when there's work to be done, you need your machine running smoothly and efficiently. We are here to make sure your heavy equipment keeps getting the job done, season after season. We are proud to provide quality service in the Hill Country Area and beyond.
          </p>
          <p>We are proud to provide quality service in the Comfort area.</p>
        </div>
      </Col>
      <Col lg={6} className="C-order">
        <div className="Defalut-img left-blue">
            <Image src="/img/HeavyEquipment.jpg" />
        </div>
      </Col>
    </Row>
    <div className="my-80">
    <Row className="align-items-center">
      <Col lg={6}>
        <div className="Defalut-img right-blue">
            <Image src="/img/ServiceTruck.png" />
        </div>
      </Col>
      <Col lg={6}>
        <div className="Defalut-info mx-auto">
          <h4>Service Trucks in the Hill Country / Comfort, Texas</h4>
          <p>We have a full-service shop in Comfort, TX that can handle your construction equipment repair and maintenance needs.</p>
          <p>We have field service trucks available. That can come to your location to repair your equipment if needed. We also have equipment available for rent while your unit is down. Let us help you keep your excavator, bulldozer, mower, or other heavy equipment up and running no matter the conditions!</p>
          <hr />
          <h4>Schedule Service</h4>
          <p>Reliability is critical when you invest in the machinery you need, and we have the tools and experience to get your heavy equipment and more in prime shape. Give us a call at 830-995-5600 and we'll be happy to help you over the phone or assist you in making an appointment. We'll get you in and out of the shop in an efficient and timely manner, so you can get back to work.</p>
          <p>Call, visit, or contact us today. We're here to help.</p>
        </div>
      </Col>
    </Row>
    </div>
    </>
  );
}
export default ServiceBox;
