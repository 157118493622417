import React from 'react';

function EmailBody({firtName, lastName, email, companyName, phoneNumber, subject, city, postal, isReplyEmail, isReplyPhone, isReplyNotRequired, message}) {
    return (
        <div>
            <h1>New Customer Contact Form Information</h1>
            <table>
                <tr>
                    <td>First Name:</td>
                    <td>{firtName}</td>
                </tr>
                <tr>
                    <td>Last Name:</td>
                    <td>{lastName}</td>
                </tr>
                <tr>
                    <td>Email:</td>
                    <td>{email}</td>
                </tr>
                <tr>
                    <td>Company Name:</td>
                    <td>{companyName}</td>
                </tr>
                <tr>
                    <td>Phone Number:</td>
                    <td>{phoneNumber}</td>
                </tr>
                <tr>
                    <td>Subject:</td>
                    <td>{subject}</td>
                </tr>
                <tr>
                    <td>City:</td>
                    <td>{city}</td>
                </tr>
                <tr>
                    <td>Postal:</td>
                    <td>{postal}</td>
                </tr>
                <tr>
                    <td>Preferred Contact Method:</td>
                    <td>
                        <ul>
                            <li>{isReplyEmail ? "Email" : null}</li>
                            <li>{isReplyPhone ? "Phone" : null}</li>
                            <li>{isReplyNotRequired ? "No preference" : null}</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Message:</td>
                    <td>{message}</td>
                </tr>
            </table>
        </div>
    );
}

export default EmailBody;
