import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DownloadEquipment.css";

function DownloadEquipment() {
  return (
    <div className="">
        <div className="WhiteBoxWrap EquipmentServiced">
            <div className="boxInfo">
                <h4>Download equipment catalogs</h4>
                <p>Not ready to rent online? Download a copy of the world's largest equipment catalog to take with you. </p>
                <a href="mailto:sales@prorental.com" className="btn btn-blue">Contact Now For Catalog</a>
            </div>
            <div className="boxImg">
                <Image src="/img/DownloadEquipment.png" />
            </div>
        </div>
    </div>
  );
}
export default DownloadEquipment;
